.password-reset {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
}

.password-reset h2 {
    margin-top: 0;
    font-size: 1.5rem;
}

.password-reset label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.password-reset input {
    width: 97%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.password-reset button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.password-reset button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    margin-top: 5px;
}