/* Login.css */

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    height: 100%;
    background-color: #f4f4f4;
    font-family: Arial, sans-serif;
    margin-top: 5rem;
}

.login-title {
    font-size: 28px;
    margin: 0;
    margin-bottom: 1rem;
    color: orange;
    background-color: #333;
    height: 2.5rem;
    padding: 1rem;
    padding-bottom: .5rem;
}

.login-form {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 0 1rem black;
}

.login-label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}

.login-input {
    width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.login-button {
    background-color: #3366cc;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #254e9b;
}

.login-success {
    background-color: #dff0d8;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    color: #3c763d;
}

.login-success h3 {
    margin-top: 0;
    color: #4cae4c;
}