html{
  padding: 0;
  margin: 0;
}
body {
  display: flex;
  justify-content: center;
  /* padding: 2rem;  */
  background-color: lightgrey;
  color: black;
  height: 100vh;
  /* Set the body's height to the full viewport height */
  margin: 0;
}


/* Change the width of the vertical scrollbar */
body::-webkit-scrollbar {
  width: 1px;
}

/* Change the width of the horizontal scrollbar */
body::-webkit-scrollbar-horizontal {
  height: 10px;
}




.main-heading-div{
display: flex;
left: 0;
  margin-bottom: 0;
  position: fixed;
  width: 100%;
  background-color: black;
  height: 10rem;
  align-items: center;
  justify-content:flex-start;
  text-align: left;
}
.welcome{
  justify-content: left;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
}
.initial-meeting{
position: fixed;
bottom: 1rem;
right: 1rem;
transition: .3s;
color: orange;
background-color: black;
box-shadow: .5rem .5rem 1rem black;
}
.initial-meeting:hover{
  opacity: .9;
}
.initial-meeting:active {
  color: orange;
  background-color: black;
  box-shadow: .25rem .25rem .5rem black;
}
p{
  font-size: large;
  text-indent: 2rem;
}
span{
  color: blue;
  cursor: pointer;
}
button{
  margin: .5rem;
}
/* In App.css */
.App {
  text-align: center;
  padding: 0rem;
  padding-top: 12rem;
  display: flex;
  /* Use flexbox */
  flex-direction: column;
  /* Stack items vertically */
  align-items: center;
  /* Center items horizontally */
  min-height: 100%;
  /* Ensure the container takes at least the full viewport height */
}
.cursive-text {
  font-family: 'Dancing Script', cursive;
  /* Use the imported font */
}
.finsln{
font-size: 5rem;
    font-family: 'Parisienne', cursive;
    /* Adjust font size as needed */
    color: white;
    margin-left: 2rem;
  }

.s{
  font-size: 7rem;
  text-decoration: none;
}
.link{
  text-decoration: none;
}

.lead{
position: absolute;
top: 6rem;
right: 1rem;
font-size: 1.5rem;
color: blue;
}

.credentials {
  justify-content: space-between;
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: calc(20% - 2rem);
  gap: 1rem;
}

.login, .register{
  width: 40%;
  margin-left: 1rem;  
}
.login:hover,
.register:hover {
  opacity: .7;
}
.login-name{
  position: fixed;
  right: 1rem;
  width: 7rem;
  font-weight: bold;
  justify-content: space-around;
}
.logout{
  color: navy;
  /* font-size: large; */
}
.login-name:hover .logout{
  color: red;
}
@media (max-width:1080px) {
  .credentials {
    flex-direction: column;
    top: 10rem;
    left:1rem;
    width: calc(100%);

  }
  .login-name{
    position: fixed;
    width: auto;
    top: 10rem;
    right:1rem;
  }
.initial-meeting {
  position:relative;
}
}

.welcome-heading{
  font-size: 3.4rem;
  font-variant: small-caps;
}