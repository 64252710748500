/* Style for the entire Terms and Conditions component */
.terms-and-conditions {
    font-family: Arial, sans-serif;
    margin: 20px;
    text-align: left;
}

/* Heading style */
.terms-and-conditions h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

/* Paragraph styles */
.terms-and-conditions p {
    font-size: 16px;
    line-height: 1.5;
}

/* Ordered list styles */
.terms-and-conditions ol {
    padding-left: 20px;
    margin-bottom: 20px;
}

/* List item styles */
.terms-and-conditions li {
    margin-bottom: 10px;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .terms-and-conditions h2 {
        font-size: 20px;
    }

    .terms-and-conditions p {
        font-size: 14px;
    }
}