.add-business-form {
    width: auto;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.8);
    background-color: #fff;
    overflow-x: hidden;
    text-align: left;
    border-radius: .5rem;
}

.add-business-form h3 {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.add-business-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.add-business-form input,
.add-business-form select {
    width: 95%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.add-business-form select {
    height: 34px;
}

.add-business-form button {
    padding: .75rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    width: auto;
}

.add-business-form button:hover {
    background-color: #0056b3;
}

.add-business-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

button[type="back-button"] {
    background-color: #e74c3c;
    color: white;
}

button:hover {
    opacity: .7;
}