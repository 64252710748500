.solutions-container{
position: relative;
}
.solution-heading{
    font-family: 'Courier New', Courier, monospace;
    font-size: 3rem;
    /* position: fixed; */
}
.solution-title{
color: maroon;
font-size: x-large;
}
.soution-description{
font-size: large;
}
.record-detail{
    background-color: #f4f4f4;
    color: aliceblue;
    border-radius: .25rem;
    padding: 1rem;
    text-align: left;
    box-shadow: 1rem 1rem 1rem black;
    margin: 2rem;
    width: 80vw;
    color: black;

}
.record-detail:active{
    transform: scale(.999) rotateX(3deg);
    box-shadow: .75rem .5rem .5rem black;
}

.back-to-orders{
    position: fixed;
    bottom: 0rem;
    right: 0rem;
}